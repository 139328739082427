import React, { useEffect, useContext } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Modal } from '@mui/material';

// import './Edit.css';
import { updateRecords } from '../../api';
import { convertTemperature, convertToCelsius, convertToSecond } from '../../lib/convert';

import { temperatureContext } from '../../lib/context';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40rem',
    bgcolor: '#FFFFFF',
    borderRadius: '1rem',
    boxShadow: '0px 4px 25px 0px #EAEE9E9',
    p: 0,
};

function Edit(props) {
    const { openConfig, setOpenConfig, setSaveAlert, setAlertState, selectedDeviceId, selectedIndex, translation, handleUpdate } = props;

    const tempUnit = useContext(temperatureContext);

    const [configType, setConfigType] = React.useState('info');
    const [rangeType, setRangeType] = React.useState('continuous');
    const [upperLimit, setUpperLimit] = React.useState(30);
    const [upperUnit, setUpperUnit] = React.useState(tempUnit);
    const [lowerLimit, setLowerLimit] = React.useState(20);
    const [lowerUnit, setLowerUnit] = React.useState(tempUnit);
    const [timeThreshold, setTimeThreshold] = React.useState(30);
    const [timeUnit, setTimeUnit] = React.useState('minute');
    const [deviceListOpen, setDeviceListOpen] = React.useState(false);
    const [intervalUnit, setIntervalUnit] = React.useState('minute');

    const shipmentNumberInput = React.useRef(null);
    const [shipmentNumber, setShipmentNumber] = React.useState('');
    const shipperInput = React.useRef(null);
    const [shipper, setShipper] = React.useState('');
    const startLocationInput = React.useRef(null);
    const [startLocation, setStartLocation] = React.useState('');
    const carrierInput = React.useRef(null);
    const [carrier, setCarrier] = React.useState('');
    const consigneeInput = React.useRef(null);
    const [consignee, setConsignee] = React.useState('');
    const endLocationInput = React.useRef(null);
    const [endLocation, setEndLocation] = React.useState('');
    const noteInput = React.useRef(null);
    const [note, setNote] = React.useState('');

    const upLimitInput = React.useRef(null);
    const [upLimit, setUpLimit] = React.useState(10);
    const lowLimitInput = React.useRef(null);
    const [lowLimit, setLowLimit] = React.useState(2);
    const timeLimitInput = React.useRef(null);
    const [timeLimit, setTimeLimit] = React.useState(60);
    const intervalInput = React.useRef(null);
    const [interval, setInterval] = React.useState(10);

    const emailInput = React.useRef(null);
    const [email, setEmail] = React.useState('');

    useEffect(() => {
        setUpperUnit(tempUnit);
        setLowerUnit(tempUnit);
    }, [tempUnit]);

    // const [saveAlert, setSaveAlert] = React.useState(false);

    const handleSwitch = (event) => {
        const target = event.target.id;
        if (target === 'info') {
            setConfigType('info');
        }
        if (target === 'alarm') {
            setConfigType('alarm');
        }
    };

    const handleSaveAll = () => {
        // console.log('save all click')
        let newConfig = {
            shipment_number: shipmentNumberInput.current.value,
            shipper_name: shipperInput.current.value,
            carrier_name: carrierInput.current.value,
            consignee_name: consigneeInput.current.value,
            start_location: startLocationInput.current.value,
            end_location: endLocationInput.current.value,
            note: noteInput.current.value,
            email: emailInput.current.value,
            upper_limit: convertToCelsius(parseFloat(upLimitInput.current.value), upperUnit),
            lower_limit: convertToCelsius(parseFloat(lowLimitInput.current.value), lowerUnit),
            time_limit: convertToSecond(parseInt(timeLimitInput.current.value), timeUnit),
            interval: convertToSecond(parseInt(intervalInput.current.value), intervalUnit),
            type: rangeType,
        };
        updateRecords(selectedDeviceId, selectedIndex, newConfig).then((res) => {
            console.log(res);
            handleUpdate();
        });

        console.log(newConfig);
        // console.log('close config')
        setOpenConfig(false);
        setSaveAlert(true);
        setAlertState({ severity: 'success', message: translation.saveSuccess });
    };

    return (
        <Modal open={openConfig} onClose={() => setOpenConfig(false)} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
            <Box sx={modalStyle}>
                <div className='button__container'>
                    <button className='close' onClick={() => setOpenConfig(false)}>
                        &times;
                    </button>
                </div>
                <div className='rangebar__edit'>
                    <div className='rangebar__container'>
                        <div className='rangebar__container__item' style={{ width: '35%' }}>
                            <table className='device__table'>
                                <tr>
                                    <td>
                                        <p>{translation.deviceId}</p>
                                        <button onClick={() => setDeviceListOpen(!deviceListOpen)}>{deviceListOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</button>
                                    </td>
                                </tr>
                                {deviceListOpen
                                    ? selectedDeviceId.map((id, index) => {
                                          // console.log(id)
                                            return (
                                                <>
                                                    <div className='hline'></div>
                                                    <tr>
                                                        <td>{id}</td>
                                                    </tr>
                                                </>
                                            );
                                        })
                                    : null}
                            </table>
                        </div>

                        <div className='rangebar__container__item' style={{ width: '30%', marginLeft: '0.5rem' }}>
                            <button id='info' className={configType === 'info' ? 'active' : ''} onClick={handleSwitch}>
                                {translation.information}
                            </button>
                        </div>

                        {/* <div className="vline"></div> */}

                        <div className='rangebar__container__item' style={{ width: '30%' }}>
                            <button id='alarm' className={configType === 'alarm' ? 'active' : ''} onClick={handleSwitch}>
                                {translation.configure}
                            </button>
                        </div>
                    </div>
                </div>
                <div className='modal__content'>
                    <form className='modal__form' id='infoForm' style={{ display: configType === 'info' ? 'inline' : 'none' }}>
                        <div className='modal__form__item'>
                            <label className='modal__form__item__label'>{translation.shipmentNumber}</label>
                            <div className='vline'></div>
                            <input key='shipmentNumber' name='shipmentNumber' className='modal__form__item__input' type='text' placeholder='1234567890' ref={shipmentNumberInput} />
                        </div>
                        <hr className='hline'></hr>
                        <div className='modal__form__item'>
                            <label className='modal__form__item__label'>{translation.shipper}</label>
                            <div className='vline'></div>
                            <input key='shipperName' name='shipperName' className='modal__form__item__input' type='text' placeholder='XXXXXX' ref={shipperInput} />
                        </div>
                        <hr className='hline'></hr>
                        <div className='modal__form__item'>
                            <label className='modal__form__item__label'>{translation.carrier}</label>
                            <div className='vline'></div>
                            <input key='carrier' name='carrier' className='modal__form__item__input' type='text' placeholder='XXXXXX' ref={carrierInput} />
                        </div>
                        <hr className='hline'></hr>
                        <div className='modal__form__item'>
                            <label className='modal__form__item__label'>{translation.consignee}</label>
                            <div className='vline'></div>
                            <input key='consignee' name='consignee' className='modal__form__item__input' type='text' placeholder='XXXXXX' ref={consigneeInput} />
                        </div>
                        <hr className='hline'></hr>
                        <div className='modal__form__item'>
                            <label className='modal__form__item__label'>{translation.startLocation}</label>
                            <div className='vline'></div>
                            <input key='startLocation' name='startLocation' className='modal__form__item__input' type='text' placeholder='XXXXXX' ref={startLocationInput} />
                        </div>
                        <hr className='hline'></hr>
                        <div className='modal__form__item'>
                            <label className='modal__form__item__label'>{translation.endLocation}</label>
                            <div className='vline'></div>
                            <input key='endLocation' name='endLocation' className='modal__form__item__input' type='text' placeholder='XXXXXX' ref={endLocationInput} />
                        </div>
                        <hr className='hline'></hr>
                        <div className='modal__form__item'>
                            <label className='modal__form__item__label'>{translation.note}</label>
                            <div className='vline'></div>
                            <input key='note' name='note' className='modal__form__item__input' type='text' placeholder='XXXXXX' ref={noteInput} />
                        </div>
                    </form>

                    <form className='modal__form' id='alarmForm' style={{ display: configType === 'alarm' ? 'inline' : 'none' }}>
                        <div className='modal__form__item__title'>{translation.failAlarm}</div>

                        <hr className='hline'></hr>
                        <div className='modal__form__item'>
                            <label className='modal__form__item__label'>{translation.excursionType}</label>
                            <div className='vline'></div>
                            <div className='rangebar__container__item excursion' style={{ width: '30%' }}>
                                <button id='continuous' type='button' className={rangeType === 'continuous' ? 'active' : ''} onClick={() => setRangeType('continuous')}>
                                    {translation.singleEvent}
                                </button>
                            </div>
                            <div className='rangebar__container__item excursion' style={{ width: '30%' }}>
                                <button id='cumulative' type='button' className={rangeType === 'cumulative' ? 'active' : ''} onClick={() => setRangeType('cumulative')}>
                                    {translation.cumulative}
                                </button>
                            </div>
                        </div>
                        <hr className='hline'></hr>
                        <div className='modal__form__item'>
                            <label className='modal__form__item__label'>{translation.upperLimit}</label>
                            <div className='vline'></div>
                            <input key='upLimit' name='upLimit' className='modal__form__item__input alarm__config' type='text' placeholder={convertTemperature(8, upperUnit)} ref={upLimitInput} />
                            <button type='button' disabled={false} className={upperUnit === 'celsius' ? 'active config__button' : 'config__button'} onClick={() => setUpperUnit('celsius')}>
                                °C
                            </button>
                            {/* <button type='button' disabled={true} className='config__button hidden'></button> */}
                            <button type='button' disabled={true} className='config__button hidden'></button>
                            <button type='button' disabled={false} className={upperUnit === 'fahrenheit' ? 'active config__button' : 'config__button'} onClick={() => setUpperUnit('fahrenheit')}>
                                °F
                            </button>
                        </div>
                        <hr className='hline'></hr>
                        <div className='modal__form__item'>
                            <label className='modal__form__item__label'>{translation.lowerLimit}</label>
                            <div className='vline'></div>
                            <input key='lowLimit' name='lowLimit' className='modal__form__item__input alarm__config' type='text' placeholder={convertTemperature(2, lowerUnit)} ref={lowLimitInput} />
                            <button type='button' disabled={false} className={lowerUnit === 'celsius' ? 'active config__button' : 'config__button'} onClick={() => setLowerUnit('celsius')}>
                                °C
                            </button>
                            {/* <button type='button' disabled={true} className='config__button hidden'></button> */}
                            <button type='button' disabled={true} className='config__button hidden'></button>
                            <button type='button' disabled={false} className={lowerUnit === 'fahrenheit' ? 'active config__button' : 'config__button'} onClick={() => setLowerUnit('fahrenheit')}>
                                °F
                            </button>
                        </div>
                        <hr className='hline'></hr>
                        <div className='modal__form__item'>
                            <label className='modal__form__item__label'>{translation.duration}</label>
                            <div className='vline'></div>
                            <input key='timeLimit' name='timeLimit' className='modal__form__item__input alarm__config' type='text' placeholder='10' ref={timeLimitInput} />
                            {/* <button type='button' className={timeUnit === 'second' ? 'active config__button' : 'config__button'} onClick={() => setTimeUnit('second')}>
                                {translation.S}
                            </button> */}
                            <button type='button' className={timeUnit === 'minute' ? 'active config__button' : 'config__button'} onClick={() => setTimeUnit('minute')}>
                                {translation.M}
                            </button>
                            <button type='button' className={timeUnit === 'hour' ? 'active config__button' : 'config__button'} onClick={() => setTimeUnit('hour')}>
                                {translation.H}
                            </button>
                            <button type='button' className={timeUnit === 'day' ? 'active config__button' : 'config__button'} onClick={() => setTimeUnit('day')}>
                                {translation.D}
                            </button>
                        </div>
                        <div className='modal__form__item__title'>{translation.dataLogging}</div>
                        <hr className='hline'></hr>
                        <div className='modal__form__item'>
                            <label className='modal__form__item__label'>{translation.loggingInterval}</label>
                            <div className='vline'></div>
                            <input key='interval' name='interval' disabled={true} className='modal__form__item__input alarm__config' type='text' placeholder='10' value='10' ref={intervalInput} />
                            {/* <button type='button' disabled={true} className={intervalUnit === 'second' ? 'active config__button' : 'config__button'} onClick={() => setIntervalUnit('second')}>
                                {translation.S}
                            </button> */}
                            <button type='button' disabled={true} className={intervalUnit === 'minute' ? 'active config__button' : 'config__button'} onClick={() => setIntervalUnit('minute')}>
                                {translation.M}
                            </button>
                            <button type='button' disabled={true} className={intervalUnit === 'hour' ? 'active config__button' : 'config__button'} onClick={() => setIntervalUnit('hour')}>
                                {translation.H}
                            </button>
                            <button type='button' disabled={true} className={intervalUnit === 'day' ? 'active config__button' : 'config__button'} onClick={() => setIntervalUnit('day')}>
                                {translation.D}
                            </button>
                        </div>
                        <div className='modal__form__item__title'>{translation.alarmNotification}</div>
                        <hr className='hline'></hr>
                        <div className='modal__form__item'>
                            <label className='modal__form__item__label'>{translation.email}</label>
                            <div className='vline'></div>
                            <input key='email' name='email' className='modal__form__item__input' type='text' placeholder='XXXXXX' ref={emailInput} />
                        </div>
                    </form>
                </div>
                <div className='button__container'>
                    <button className='save__all' onClick={handleSaveAll}>
                        {translation.saveAll}
                    </button>
                </div>
            </Box>
        </Modal>
    );
}

export default Edit;
