import { Modal, Box } from '@mui/material';
import './Confirm.css';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width:'40rem',
    bgcolor: '#FFFFFF',
    borderRadius: '1rem',
    boxShadow: '0px 4px 25px 0px #EAEE9E9',
    p: 0,
};


function Confirm(props) {
    const { records, handleConfirm, translation, openConfirm, setOpenConfirm } = props;

    const handleCancel = () => {
        setOpenConfirm(false);
    }

    return (
        <Modal
            open={openConfirm}
            onClose={() => setOpenConfirm(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <div className='button__container'>
                    <button className="close" onClick={() => {
                        // setView('add');
                        setOpenConfirm(false)
                    }}>&times;</button>
                </div>
                <div style={{textAlign: 'center', marginBottom: '2rem'}}>
                    <p style={{fontSize: '1.25rem', fontWeight: 600}}>{translation.confirmDelete}</p>
                    {records.map((record, index) => {
                        return (
                        <div key={index} style={{color: '#000000'}}>
                            <p>{record}</p>
                        </div>
                        );
                    })}
                    <div style={{margin: '2rem'}}></div>
                    <button id='cancel' onClick={handleCancel}>{translation.cancel}</button>
                    <button id='confirm' onClick={handleConfirm}>{translation.confirm}</button>
                </div>
            </Box>
        </Modal>
    );
}

export default Confirm;